<template>
  <div class="matchmaker">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
      <div class="btn" @click="toDengji">婚恋人员登记</div>
    </div>
    <div class="main">
      <div
        :class="['item', { te: index == 0 }]"
        v-for="(item, index) in itemList"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="img"><img :src="item.url" alt="" /></div>
          <div class="txt">
            <div class="txt-t">{{ item.label }}</div>
            <div class="txt-b">{{ item.info }}</div>
          </div>
        </div>
        <div class="item-r"><img src="./img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="jindu" @click="jinduSet" v-if="isshow">
      <div class="jindu-l">牵线进度设置</div>
      <div class="jindu-r"><img src="./img/arrow-new.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { gloabalCount } from "@/utils/common";
import { getHashParam } from "@/utils/utils.js";
import { getFunctionSettingUrl, userInfoUrl } from "./api";
var moment = require("moment");
export default {
  name: "matchmaker",
  data() {
    return {
      fromFlag: "",
      isshow: "",
      userInfo: {},
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    itemList() {
      let list = [
        {
          id: 1,
          url: require("./img/icon1.png"),
          label: "红娘介绍",
          info: "Hong Niang Jie Shao",
        },
        {
          id: 2,
          url: require("./img/icon2.png"),
          label: "团队介绍",
          info: "Tuan Dui Jie Shao",
        },
        {
          id: 3,
          url: require("./img/icon3.png"),
          label: "案例展示",
          info: "An Li Zhan Shi",
        },
      ];
      return list;
    },
  },
  created() {},
  async mounted() {
    await this.getuserInfo();
    this.getTel();
    gloabalCount("", 606, 1);
  },
  methods: {
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
      }
    },
    async getTel() {
      let params = {
        applicationName: "红娘",
        functionName: "有权限号码",
      };
      let res = await this.$axios.get(`${getFunctionSettingUrl}`, { params });
      if (res.code === 200) {
        if (res.data.settingOn && this.userInfo && this.userInfo.mobile) {
          if (res.data.settingOn.indexOf(this.userInfo.mobile) != -1) {
            this.isshow = true;
          }
        }
      }
    },
    jinduSet() {
      this.$router.push({
        name: "matchmakerList",
      });
    },
    toDengji() {
      this.$router.push({
        name: "matchmakerForm",
      });
    },
    toDetail(item, index) {
      if (index == 0) {
        this.$router.push({
          name: "matchmakerIntro",
        });
      }
      if (index == 1) {
        this.$router.push({
          name: "matchmakerTeamIntro",
        });
      }
      if (index == 2) {
        this.$router.push({
          name: "matchmakerCase",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmaker {
  min-height: 100vh;
}
.matchmaker {
  .banner {
    height: 316px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .btn {
      position: absolute;
      bottom: 52px;
      left: 136px;
      width: 210px;
      height: 46px;
      background: linear-gradient(180deg, #ffb3b3 0%, #f6517c 100%);
      border-radius: 26px;
      border: 2px solid #ffffff;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      line-height: 46px;
      text-align: center;
    }
  }
  .info {
    display: flex;
    padding: 28px 36px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .info-l {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-r {
      font-size: 28px;
      font-weight: 400;
      color: #1a1c34;
      line-height: 40px;
    }
  }
  .main {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 34px 56px 54px 34px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      &:nth-last-child(1) {
        border: 0;
      }
      .item-l {
        display: flex;
        align-items: center;

        .img {
          width: 140px;
          height: 142px;
          margin-right: 74px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .txt-t {
            font-weight: 700;
            font-size: 32px;
            color: #000000;
            line-height: 44px;
          }
          .txt-b {
            font-weight: 400;
            font-size: 28px;
            color: #6d7278;
            line-height: 40px;
            margin-top: 8px;
          }
        }
      }
      .item-r {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .te {
      .item-l {
        .img {
          text-align: center;
          img {
            width: 128px;
            height: 126px;
          }
        }
      }
    }
  }
  .jindu {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 36px;
    .jindu-l {
      font-weight: 400;
      font-size: 28px;
      color: #fb8133;
      line-height: 40px;
    }
    .jindu-r {
      width: 48px;
      height: 48px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
